import { Button, Icon, Flex, Box } from '@chakra-ui/react'
import Link from 'next/link'
import '../../home/style/styles.css'
import React from 'react'
// import { colors } from 'styles/foundations'
import { IoMdClose } from 'react-icons/io'
import { Logo } from 'components/logo'

type Props = {
  toggleDrawer: () => void
  isDrawerOpen: boolean
}

const NavDrawer = ({ isDrawerOpen, toggleDrawer }: Props) => {
  return (
    <Box
      w={'full'}
      display={{ md: 'none' }}
      style={{ zIndex: '9999' }}
      px={6}
      className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <Flex alignItems={'center'} justifyContent="space-between" pb={20} pt={16}>
          <Logo />
          <Icon as={IoMdClose} boxSize={12} onClick={toggleDrawer} />
        </Flex>
        {/* <Link href="/auth/login">
          <Button
            size="16px"
            w="full"
            variant={'unstyled'}
            border={`1px solid ${colors.brand.darkColors[0]}`}
            height={16}
            px={5}>
            Login
          </Button>
        </Link> */}
        <Link href="/blog">
          <Button size="16px" w="full" variant={'unstyled'} height={16} px={5}>
            Read our blog
          </Button>
        </Link>
        <Link href="https://lint.onelink.me/8QeB/1l651f8u">
          <Button size="16px" w="full" mt={10} height={16} px={5}>
            Get the app
          </Button>
        </Link>
      </div>
    </Box>
  )
}

export default NavDrawer
