'use client'

import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import React from 'react'
import { colors } from 'styles/foundations'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { RiFacebookFill } from 'react-icons/ri'
import { GrLinkedinOption } from 'react-icons/gr'

const Footer = () => {
  return (
    <Box bg={colors.brand.darkColors[0]}>
      <Box
        w={{ base: 'full' }}
        mx="auto"
        px={{ base: '2rem', md: '4rem', xl: '6rem' }}
        py={{ lg: 20, base: 20 }}>
        <Box
          display={'flex'}
          flexDirection={{ sm: 'row', base: 'column' }}
          justifyContent="space-between"
          gap={5}>
          <Link href="/">
            <Image src="/images/logo-white-white.svg" alt={'logo'} width={100} height={100} />
          </Link>
          <Flex alignItems={'center'} gap={10}>
            <Link
              target={'_blank'}
              href="https://www.facebook.com/profile.php?id=100094364470081&locale=hi_IN&paipv=0&eav=AfbNZE8OtIIhwse5wlxg1Yc1gVjT1T5xO7MpoXnw84Y0-Kl77wmKkcyOKuJrVIlQM-I">
              <Icon as={RiFacebookFill} boxSize={'10'} color={colors.brand.white} />
            </Link>
            <Link target={'_blank'} href="https://twitter.com/lint_finance">
              <Icon as={FaTwitter} boxSize={'10'} color={colors.brand.white} />
            </Link>
            <Link target={'_blank'} href="https://www.youtube.com/@LintFinance">
              <Icon as={GrLinkedinOption} boxSize={'10'} color={colors.brand.white} />
            </Link>
            <Link target={'_blank'} href="https://www.instagram.com/lint_finance/">
              <Icon as={FaInstagram} boxSize={'10'} color={colors.brand.white} />
            </Link>
          </Flex>
        </Box>

        <Box
          display={'flex'}
          flexDirection={{ sm: 'row', base: 'column' }}
          justifyContent={{ sm: 'space-between' }}>
          <Box>
            <Flex gap={5} direction="column" mt={10}>
              <Text fontSize="1.6rem" color={colors.brand.white}>
                Legal{' '}
              </Text>
              <Box color={colors.brand.white} fontSize="1.6rem">
                <Link href="/terms-of-use">Terms & Conditions</Link>
              </Box>
              <Box color={colors.brand.white} fontSize="1.6rem">
                <Link href="/policy-privacy">Privacy Policy</Link>
              </Box>
              <Box fontSize="1.6rem" textDecor="underline" color={colors.brand.white}>
                <Link href="/campus-ambassadors">Become a campus ambassador</Link>
              </Box>
              <Box fontSize="1.6rem" textDecor="underline" color={colors.brand.white}>
                <Link href="/blog">Blog</Link>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Flex direction={'column'} alignItems={{ sm: 'end', base: 'flex-start' }}>
              <Text fontSize="1.6rem" mt={10} color={colors.brand.white}>
                2 Rabo Close Fwavei-Ciki, Rayfield
              </Text>
              <Text fontSize="1.6rem" color={colors.brand.white}>
                Jos, Plateau State, Nigeria
              </Text>
            </Flex>
            <Flex direction={'column'} alignItems={{ sm: 'end', base: 'flex-start' }} gap={6}>
              <Box mt={6} fontSize="1.6rem" textDecor="underline" color={colors.brand.white}>
                <Link target={'_blank'} href="mailto:growth@lint.finance">
                  growth@lint.finance
                </Link>
              </Box>
              <Box fontSize="1.6rem" textDecor="underline" color={colors.brand.white}>
                <Link target={'_blank'} href="https://wa.me/2348027179804">
                  Whatsapp
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
